import React, { PureComponent } from 'react';
import { Button, Form, Select, Switch } from 'antd';
import service from '../../core/services/reports';
import styles from './filters-sidebar.module.css';
import usersService from '../../core/services/users';

const FormItem = Form.Item;

class FiltersSidebar extends PureComponent {
    state = {
        areas: [],
        locations: [],
        users: [],
        costCenters: [],
        courses: [],
        modules: [],
        modulesFetched: false,
        areasFetched: false,
    };

    async componentDidMount() {
        const courses = this.props.selectCourse
            ? await service.getCourses()
            : [];
        const areas = await service.getAreas();
        const locations = await service.getLocations();
        const custom_fields = await service.getCustomFields();
        const extValues = {};

        for (let cf of custom_fields) {
            extValues[cf.field_name] = await service.getExtendedFieldValues(
                Number(cf.field_name.replace('extended_field', ''))
            );
        }

        this.setState({
            courses: courses.results,
            custom_fields,
            areas: areas.results,
            locations,
            ...extValues,
            areasFetched: true,
        });
    }

    handleCourseChange = async (value) => {
        if (!this.props.selectModules) return;
        this.setState({ modulesFetched: false, modules: [] });
        const modules = await service.getModules(value);
        this.setState({ modules, modulesFetched: true });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.onFilter(values);
            }
        });
    };

    handleSearch = async (value) => {
        try {
            var response = await usersService.search(value);
            this.setState({ users: (response || {}).results || [] });
        } catch (ex) {
            console.error(ex);
        }
    };

    render() {
        const {
            buttonText,
            form,
            fetching,
            selectCourse,
            selectModules,
            courseMultiple,
        } = this.props;
        const { custom_fields } = this.state;
        const { getFieldDecorator } = form;

        return (
            <div className={`content-internal ${styles.filters}`}>
                <Form
                    onSubmit={this.handleSubmit}
                    className={styles.form}
                    layout="horizontal"
                >
                    {(selectCourse || selectModules) && (
                        <FormItem help={false}>
                            {getFieldDecorator('course', {
                                rules: [
                                    {
                                        required: !courseMultiple,
                                    },
                                ],
                            })(
                                <Select
                                    placeholder={
                                        courseMultiple ? 'Cursos' : 'Curso'
                                    }
                                    mode={
                                        courseMultiple ? 'multiple' : undefined
                                    }
                                    notFoundContent={`${
                                        this.state.areasFetched
                                            ? 'No se encontraron cursos'
                                            : 'Cargando...'
                                    }`}
                                    onChange={this.handleCourseChange}
                                >
                                    {(this.state.courses || []).map((c) => (
                                        <Select.Option key={c.id} value={c.id}>
                                            {c.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </FormItem>
                    )}
                    {selectModules && (
                        <FormItem help={false}>
                            {getFieldDecorator('module', {
                                rules: [
                                    {
                                        required: true,
                                    },
                                ],
                            })(
                                <Select
                                    placeholder="Módulo"
                                    notFoundContent={`${
                                        this.state.areasFetched
                                            ? this.state.modulesFetched
                                                ? 'No se encontraron módulos'
                                                : 'Cargando...'
                                            : 'Seleccione un curso'
                                    }`}
                                >
                                    {(this.state.modules || []).map((m) => (
                                        <Select.OptGroup
                                            key={m.id}
                                            label={`${m.order}. ${m.name}`}
                                        >
                                            {m.sections.map((sm) => (
                                                <Select.Option
                                                    key={sm.id}
                                                    value={`${m.id},${sm.id}`}
                                                >{`${m.order}.${sm.order}. ${sm.name}`}</Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    ))}
                                </Select>
                            )}
                        </FormItem>
                    )}
                    <FormItem help={false}>
                        {getFieldDecorator('areas', {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(
                            <Select
                                placeholder="Áreas"
                                showArrow={true}
                                mode="multiple"
                                maxTagCount={3}
                                notFoundContent={`${
                                    this.state.areasFetched
                                        ? 'No se encontraron areas'
                                        : 'Cargando...'
                                }`}
                            >
                                {(this.state.areas || []).map((c) => (
                                    <Select.Option key={c.id} value={c.id}>
                                        {c.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem help={false}>
                        {getFieldDecorator('location', {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(
                            <Select
                                placeholder="Ubicación"
                                showArrow={true}
                                mode="multiple"
                                maxTagCount={3}
                                notFoundContent={`${
                                    this.state.areasFetched
                                        ? 'No se encontraron ubicaciones'
                                        : 'Cargando...'
                                }`}
                            >
                                {(this.state.locations || []).map((c) => (
                                    <Select.Option
                                        key={c.location}
                                        value={c.location}
                                    >
                                        {c.location}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                    {(custom_fields || []).map((cf) => (
                        <FormItem key={cf.field_name} help={false}>
                            {getFieldDecorator(cf.field_name, {
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                            })(
                                <Select
                                    placeholder={cf.field_display_name}
                                    showArrow={true}
                                    mode="multiple"
                                    maxTagCount={3}
                                    notFoundContent={`${
                                        this.state.areasFetched
                                            ? 'No se encontraron resultados'
                                            : 'Cargando...'
                                    }`}
                                >
                                    {(this.state[cf.field_name] || []).map(
                                        (c) => (
                                            <Select.Option key={c} value={c}>
                                                {c}
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            )}
                        </FormItem>
                    ))}
                    <FormItem help={false}>
                        {getFieldDecorator('manager', {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                placeholder="Seleccione el jefe"
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                mode="multiple"
                                filterOption={false}
                                notFoundContent="No hay resultados"
                                onSearch={this.handleSearch}
                            >
                                {this.state.users.map((d) => (
                                    <Select.Option
                                        key={d.id}
                                    >{`${d.first_name} ${d.last_name}`}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem help={false}>
                        <div className={styles.switchCont}>
                            <span>Solo activos</span>
                            {getFieldDecorator('onlyActive', {
                                valuePropName: 'checked',
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                            })(<Switch />)}
                        </div>
                    </FormItem>
                    <FormItem>
                        <Button
                            block
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={fetching}
                        >
                            {buttonText}
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default Form.create()(FiltersSidebar);
