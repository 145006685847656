import React, { PureComponent } from 'react';
import { Button, Row, Col, Icon, Spin, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';

import { actions } from './reports.redux';
import FiltersSidebar from './components/filters-sidebar';
import DateDisplay from '../common/components/date';

class CoursesReportPage extends PureComponent {
    state = {
        reportGenerated: false,
        sortInfo: null,
    };

    componentDidMount() {
        if (this.props.report_data && this.props.report_data.length > 0)
            this.setState({ reportGenerated: true });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.fetching && !nextProps.fetching) {
            if (!this.props.error) {
                this.setState({ reportGenerated: true });
            } else {
                //set error
            }
        }
    }

    renderTable = (report_data) => {
        return (
            <div>
                <Button
                    type="primary"
                    icon="download"
                    onClick={this.downloadReport}
                >
                    Exportar
                </Button>
                <Table
                    rowKey={(u) => u.id}
                    columns={[
                        {
                            title: 'Curso',
                            dataIndex: 'course_name',
                            sorter: (a, b) =>
                                a.course_name.localeCompare(b.course_name),
                        },
                        {
                            title: 'Cédula',
                            dataIndex: 'identification_number',
                            sorter: (a, b) =>
                                a.identification_number.localeCompare(
                                    b.identification_number
                                ),
                        },
                        {
                            title: 'Nombre',
                            dataIndex: 'full_name',
                            sorter: (a, b) =>
                                a.full_name.localeCompare(b.full_name),
                        },
                        {
                            title: 'Ubicación',
                            dataIndex: 'location',
                            sorter: (a, b) =>
                                a.location.localeCompare(b.location),
                        },
                        {
                            title: 'Área',
                            dataIndex: 'area_name',
                            sorter: (a, b) =>
                                a.area_name.localeCompare(b.area_name),
                        },
                        {
                            title: 'Cargo',
                            dataIndex: 'position_name',
                            sorter: (a, b) =>
                                a.position_name.localeCompare(b.position_name),
                        },
                        {
                            title: 'Jefe',
                            dataIndex: 'manager_name',
                            sorter: (a, b) =>
                                a.manager_name.localeCompare(b.manager_name),
                        },
                        ...(report_data.custom_fields || []).map((cf) => ({
                            title: cf.field_display_name,
                            dataIndex: cf.field_name,
                            sorter: (a, b) =>
                                a[cf.field_name].localeCompare(
                                    b[cf.field_name]
                                ),
                        })),
                        {
                            title: 'Avance',
                            dataIndex: 'percentage_completed',
                            render: (val, record) => (
                                <span>
                                    {record.external_revalidated && (
                                        <Tooltip
                                            title={
                                                <div>
                                                    <strong>
                                                        Convalidado:
                                                    </strong>
                                                    <br />
                                                    {
                                                        record.revalidation_comments
                                                    }
                                                </div>
                                            }
                                        >
                                            <Icon
                                                type="safety-certificate"
                                                style={{
                                                    cursor: 'pointer',
                                                    verticalAlign: 'middle',
                                                    color: 'gold',
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                    {'   ' + (val ?? 0).toFixed(2) + '%'}
                                </span>
                            ),
                            sorter: (a, b) =>
                                a.percentage_completed - b.percentage_completed,
                            defaultSortOrder: 'descend',
                            align: 'right',
                        },
                        {
                            title: 'Fecha de finalización',
                            dataIndex: 'date_completed',
                            sorter: (a, b) =>
                                new Date(a.date_completed) -
                                new Date(b.date_completed),
                            render: (val, record) => {
                                return val ? (
                                    <DateDisplay format="PPpp">
                                        {val}
                                    </DateDisplay>
                                ) : (
                                    ''
                                );
                            },
                        },
                        {
                            title: 'Puntos',
                            dataIndex: 'points',
                            sorter: (a, b) => a.points - b.points,
                        },
                    ]}
                    dataSource={report_data.data}
                    size="middle"
                    pagination={false}
                    scroll={{ x: true }}
                    rowClassName={(record, index) =>
                        index % 2 === 0 ? 'row-normal' : 'row-alternate'
                    }
                    onChange={this.handleTableChange}
                />
            </div>
        );
    };

    toCSVValue = (val, delimiter) => {
        var t = typeof val,
            output;
        if (t === 'undefined' || t === null || val === null) {
            output = '';
        } else if (t === 'string') {
            output = delimiter + val + delimiter;
        } else {
            output = String(val);
        }

        return output;
    };

    toCSV(
        objArray,
        excludedProps = [],
        delimiter = ',',
        stringDelimiter = '"'
    ) {
        var i,
            l,
            names = [],
            name,
            value,
            obj,
            row,
            output = '',
            n,
            nl;

        for (i = 0, l = objArray.length; i < l; i += 1) {
            // Get the names of the properties.
            obj = objArray[i];
            row = '';
            if (i === 0) {
                // Loop through the names
                for (name in obj) {
                    if (
                        obj.hasOwnProperty(name) &&
                        excludedProps.indexOf(name) < 0
                    ) {
                        names.push(name);
                        row += [
                            stringDelimiter,
                            name,
                            stringDelimiter,
                            delimiter,
                        ].join('');
                    }
                }
                row = row.substring(0, row.length - 1);
                output += row;
            }

            output += '\n';
            row = '';
            for (n = 0, nl = names.length; n < nl; n += 1) {
                name = names[n];
                value = obj[name];
                if (n > 0) {
                    row += delimiter;
                }
                row += this.toCSVValue(value, stringDelimiter);
            }
            output += row;
        }

        return output;
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            sortInfo: sorter,
        });
    };

    downloadReport = () => {
        let data = [...this.props.report_data.data];

        for (let datum of data) {
            for (let cust of this.props.report_data.custom_fields) {
                datum[cust.field_display_name] = datum[cust.field_name];
                delete datum[cust.field_name];
            }
        }

        this.downloadCSV(this.toCSV(data), 'reporte.csv');
    };

    downloadCSV = (data, fileName) => {
        var csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        //IE11 & Edge
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(csvData, fileName);
        } else {
            //In FF link must be added to DOM to be clicked
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(csvData);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    renderReport = () => {
        const { report_data } = this.props;
        if (!report_data) return <div />;
        return <div>{this.renderTable(report_data)}</div>;
    };

    queryReport = (values) => {
        this.setState({ filters: values });

        const queryFilters = {};
        if (values.course) queryFilters.courses = values.course;
        if (values.areas) queryFilters.area = values.areas;
        if (values.location) queryFilters.location = values.location;
        if (values.manager) queryFilters.manager = values.manager;
        if (values.extended_field1)
            queryFilters.extended_field1 = values.extended_field1;
        if (values.extended_field2)
            queryFilters.extended_field2 = values.extended_field2;
        if (values.extended_field3)
            queryFilters.extended_field3 = values.extended_field3;
        if (values.extended_field4)
            queryFilters.extended_field4 = values.extended_field4;
        if (values.extended_field5)
            queryFilters.extended_field5 = values.extended_field5;
        if (values.extended_field6)
            queryFilters.extended_field6 = values.extended_field6;
        if (values.extended_field7)
            queryFilters.extended_field7 = values.extended_field7;
        if (values.onlyActive) queryFilters.onlyActive = values.onlyActive;
        this.props.getreport(queryFilters);
    };

    renderEmpty = () => {
        return (
            <div style={{ textAlign: 'center', margin: 20 }}>
                <Icon
                    type="reconciliation"
                    theme="twoTone"
                    style={{ fontSize: '240px', opacity: 0.3 }}
                />
                <p style={{ fontSize: '20px', color: '#ccc' }}>
                    Para generar el reporte seleccione los filtros y presione el
                    botón
                </p>
            </div>
        );
    };

    render() {
        return (
            <Row gutter={16}>
                <Col span={18}>
                    <div className="content-internal">
                        <Spin spinning={this.props.fetching}>
                            {this.state.reportGenerated && !this.props.fetching
                                ? this.renderReport()
                                : this.renderEmpty()}
                        </Spin>
                    </div>
                </Col>
                <Col span={6}>
                    <FiltersSidebar
                        buttonText="Generar reporte"
                        fetching={this.props.fetching}
                        onFilter={this.queryReport}
                        selectCourse={true}
                        courseMultiple={true}
                    />
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return state.reports;
}

function mapDispatchToProps(dispatch) {
    return {
        getreport: (filters) => dispatch(actions.getcoursesreport(filters)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesReportPage);
